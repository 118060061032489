@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
 
    font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
div{
  font-family: 'Outfit', sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-section {
  @apply 2xl:max-w-[1800px] mx-auto   md:w-[95%];
}
.custom-button {
  @apply  py-3  md:px-6 px-4  lg:text-base text-center flex justify-center items-center text-nowrap max-sm:text-xs md:text-xs  font-bold rounded-full border ;
}
.sub-section {
  font-family: sans-serif;
  @apply mx-auto md:px-5 px-2 lg:w-[87%] md:w-[95%]  2xl:max-w-[1600px];
}
section {
  @apply mt-5 py-5   md:py-10;
}
.title {
  @apply 2xl:text-3xl font-bold sm:text-2xl max-sm:text-2xl lg:text-3xl text-cblack;
}
.sub-title {
  @apply 2xl:text-lg font-medium mt-3 sm:text-xs lg:text-base max-sm:text-sm  text-secondary px-5;
}
.custom-shadow {
  box-shadow: 0px 0px 70px 0px rgba(134, 147, 148, 0.16);
  border-radius: 10px;
}

.swiper-button-next:after {
  @apply !text-xl;
}
.swiper-button-prev:after {
  @apply !text-xl absolute;
}
.stepper-bg {
  background-image: url('./assets/images/backgroundimage/stepperbg.png');
  background-size: 100% 100%;
}

.chevron {
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  color: red !important;
  height: 100px;
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}
.react-calendar__tile--active {
  @apply !bg-primary rounded-full;
}
.szh-accordion__item-btn {
  @apply flex justify-between w-full items-center text-start;
}
.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  @apply px-3 text-white py-1;
}
.react-tabs__tab--selected {
  @apply !border-none;
}
.react-tabs__tab-list {
  @apply !border-none;
}
.swiper-button-prev:after {
  content: '' !important;
  background-image: url('/src/assets/leftarrow.svg') !important;
  
  background-size: 100% 100%;
  padding: 12px;
}
.swiper-button-next:after {
  content: '' !important;
  background-image: url('/src/assets/rightarrow.svg') !important;
  background-size: 100% 100%;
  padding: 12px;
}
.jobdetailbanner {
  background-image: url('/src//assets//images/graphbanner.png');
  background-size: 100% 100%;
  object-position: center;
}
.fs24 {
  @apply 2xl:text-2xl max-2xl:text-lg font-semibold  max-md:text-base  font-sans;
}
.fs20 {
  @apply 2xl:text-xl max-2xl:text-base max-sm:text-sm font-semibold font-sans;
}
.fs36 {
  @apply 2xl:text-3xl max-2xl:text-xl max-sm:text-base font-semibold;
}
.fs16 {
  @apply 2xl:text-base max-2xl:text-xs font-medium font-sans;
}
.fs18 {
  @apply 2xl:text-lg max-2xl:text-sm  font-medium;
}
.custom-bg {
  background-color: rgba(244, 246, 251, 1);
}

.checkbox-green {
  width: 1em;
  height: 1em;
  background-color: #2db2c4;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2db2c4;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative; /* Added */
}

.checkbox-green:checked::after {
  content: '\2714'; /* Unicode checkmark character */
  font-size: 0.6em; /* Adjust size as needed */
  color: white !important; /* Color of the checkmark */
  font-weight: bold;
  position: absolute; /* Changed to absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkbox-white {
  width: 1em;
  height: 1em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2db2c4;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative; /* Added */
}

.checkbox-white:checked::after {
  content: '\2714'; /* Unicode checkmark character */
  font-size: 0.6em; /* Adjust size as needed */
  color: #2db2c4; /* Color of the checkmark */
  font-weight: bold;
  position: absolute; /* Changed to absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  @apply !mx-auto !p-5;
}
/* .swiper-wrapper {
  background: transparent !important  ;
  padding: 20px !important;
}
.carasol1 .swiper-css-mode.swiper-horizontal > .swiper-wrapper{
   width: 90% !important;
  margin: 0 auto !important;

} */
/* .swiper-css-mode.swiper-horizontal > .swiper-wrapper{
  @apply !w-[90%] !mx-auto
} */
.swiper-button-next {
  @apply !pl-5;
}
.swiper-button-prev {
  @apply !pr-5;
}
.checkbox-gray {
  width: 1em;
  height: 1em;
  background-color: #777777;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #777777;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative; /* Added */
}
.checkbox-gray:checked::after {
  content: '\2714'; /* Unicode checkmark character */
  font-size: 0.6em; /* Adjust size as needed */
  color: white; /* Color of the checkmark */
  font-weight: bold;
  position: absolute; /* Changed to absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* For WebKit browsers */
::-webkit-scrollbar {
  /* height: 1px !important;
  width: 80% !important;
  border-radius: 50%;

  color: #2db2c4;
  background-color: #2db2c4; */
  display: none;
}

/* For Firefox */
.react-tabs__tab {
  padding-left: 0 !important;
}
.responsive-width {
  width: calc(100% / 3 -10px) !important;
}
#loom-companion-mv3 {
  display: none !important;
}

.wave-banner {
  background-image: url('./assets/images/wavebanner.png');
  background-size: 100% 100%;
}
.swiper-pagination-horizontal {
  margin-top: 35px !important;
  position: relative !important;
}
.swiper-pagination-bullet {
  width: 30px !important;
  height: 10px !important;
  border-radius: 10px !important;
  background-color: #2db2c4 !important;
}
.swiper-css-mode.swiper-horizontal > .swiper-wrapper {
  background-color: white4 !important;
  background: white4 !important;
}
.cardcarasol .swiper-css-mode.swiper-horizontal > .swiper-wrapper {
  @apply !w-[90%] !mx-auto;
}

.table-responsive-co6 table tr {
  @apply !flex !bg-white text-start justify-between p-2;
}

.swiper-grid-column > .swiper-wrapper {
  flex-direction: row !important;
}
.swiper-backface-hidden .swiper-slide {
  margin-top: 0 !important;
}
@media screen and (max-width: 950px) {
  .career-portal-cards {
    @apply !grid-cols-2;
  }
}
@media screen and (max-width: 870px) {
  .otpsection{
    @apply  flex-col
  }
}
.swiper-horizontal{
  background-color: white !important;
}
